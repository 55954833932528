import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { ChooseGift } from "./Pages/ChooseGift";

function App() {
  return <ChooseGift />;
}

export default App;
