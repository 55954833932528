import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const getScriptInnerHtml = (attr) => {
  return document.querySelector(`script[${attr}]`)?.innerHTML?.trim() || "";
};
document.addEventListener("DOMContentLoaded", () => {
  window.chargezenProduct = getScriptInnerHtml("chargezen-product-json");
  if (window.chargezenProduct) {
    window.chargezenProduct = JSON.parse(window.chargezenProduct);
  }
  window.chargezenLocale = {
    per_bag: getScriptInnerHtml("chargezen-subGift-perbag") || "per bag",
    oneTimeLocale: {
      headingTitle:
        getScriptInnerHtml("chargezen-subGift-oneTime-heading") ||
        "One-Time Purchase",
      headingSubTitle:
        getScriptInnerHtml("chargezen-subGift-oneTime-subheading") ||
        "$1.95 Shipping",
    },
    subscriptionLocale: {
      headingTitle:
        getScriptInnerHtml("chargezen-subGift-sub-heading") ||
        "Subscribe & Save",
      headingSubTitle:
        getScriptInnerHtml("chargezen-subGift-sub-subheading") ||
        "First Bag Free & Free Shipping",
      contentBox: {
        topHeading:
          getScriptInnerHtml("chargezen-subGift-sub-contentbox-topHeading") ||
          "This coffee is part of our",
        middleHeading:
          getScriptInnerHtml(
            "chargezen-subGift-sub-contentbox-middleHeading"
          ) || "The Medium Roast Coffee Collection",
        bottomHeading:
          getScriptInnerHtml(
            "chargezen-subGift-sub-contentbox-bottomHeading"
          ) || "44 Coffees",
        image:
          getScriptInnerHtml("chargezen-subGift-sub-contentbox-image") ||
          "https://www.drinktrade.com/cdn/shop/collections/MEDIUM_ROAST_300x300.jpg?v=1708698673",
      },
      moreInfoText:
        getScriptInnerHtml("chargezen-subGift-sub-contentbox-moreInfoText") ||
        "More Info",
      moreInfoLink:
        getScriptInnerHtml("chargezen-subGift-sub-contentbox-moreInfoLink") ||
        "/",
      listHeader:
        getScriptInnerHtml("chargezen-subGift-sub-contentbox-listHeader") ||
        "Subscribe to Collection",
      lists: [
        getScriptInnerHtml("chargezen-subGift-sub-contentbox-list1"),
        getScriptInnerHtml("chargezen-subGift-sub-contentbox-list2"),
        getScriptInnerHtml("chargezen-subGift-sub-contentbox-list3"),
      ],
    },
  };
  const root = ReactDOM.createRoot(
    document.getElementById("chargezen_csa_widget")
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
